<template>
    <div class="box" ref="box">
            <div class="booking-info-overlay" ref="overlay">
                <slot></slot>
            </div>
            <google-map
                    class="map"
                    containerStyles="width: 100%; height: 100%; position: absolute;"
                    :map-config='{
                        zoom: 12,
                        center: mapLatLng,
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        //scrollwheel: false,
                        //minZoom: 13,
                        //maxZoom: 13,
                        options: {
                            gestureHandling: gestureHandling //"greedy"
                        },
                        styles: [
                            {
                                "elementType": "geometry",
                                "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                {
                                    "visibility": "off"
                                }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                {
                                    "color": "#616161"
                                }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                                ]
                            },
                            {
                                "featureType": "administrative.land_parcel",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                {
                                    "color": "#757575"
                                }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                                ]
                            },
                            {
                                "featureType": "road",
                                "elementType": "geometry",
                                "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                {
                                    "color": "#757575"
                                }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry",
                                "stylers": [
                                {
                                    "color": "#dadada"
                                }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                {
                                    "color": "#616161"
                                }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                                ]
                            },
                            {
                                "featureType": "transit.line",
                                "elementType": "geometry",
                                "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                                ]
                            },
                            {
                                "featureType": "transit.station",
                                "elementType": "geometry",
                                "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                                ]
                            }
                            ]
                    }'
                    :offsetX="offsetW"
                    :overlayW="overlayW"
                    :bounds="bounds"
                    :libraries="['drawing']">
                <template  slot-scope="{ google, map }">
                    <map-marker v-for='(marker, index) in markers' :key='index' :google="google" :map="map" :position="marker" />
                    <map-polygon v-for='(pathGroup, index) in pathDataArray' :key='index' :google="google" :map="map" :paths="pathGroup.coords" :options="pathGroup.options" />
                </template>
            </google-map>
    </div>
</template>

<script>
    // bounds structure [{lat: 55.495972, lng: 9.473052},{lat: 50.495972, lng: 100.473052},{lat: 50.495972, lng: 9.473052}]
    import GoogleMap from '@/components/maps/GoogleMap';
    import MapPolygon from '@/components/maps/MapPolygon';
    import MapMarker from '@/components/maps/MapMarker';
    export default {
        name: "MapWithDetails",
        components: {GoogleMap,MapPolygon,MapMarker},
        props:{
            mapLatLng: {
                type: Object,
                default: () => {return {lat: 0, lng: 0}}
            },
            pathDataArray:{
                type: Array,
                default: () => []
            },
            bounds: {
                type: Array,
                default: () => {}
            },
            markers: {
                type: Array,
                default: () => []
            },
            gestureHandling: {
                type: String,
                default: "greedy"
            }
        },
        data(){
            return {
                overlayW: 0,
                offsetW: 0,
            };
        },
        mounted() {
            const boxW = this.$refs.box.getBoundingClientRect().width;
            
            if(boxW > 700){
                this.overlayW = this.$refs.overlay.getBoundingClientRect().width;
                this.offsetW = -this.overlayW/2;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .box{
        display: flex;
        flex-direction: row;
    }

    .booking-info-overlay{
        flex: 0.33;
        display: flex;
        flex-direction: column;
        display: block;
        position: relative;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 40px 30px;
        background: rgba(63,74,80,0.9);
        font-size: 1.125rem;
        color: $white;
        z-index: 100;
        min-height: 374px;
	    a{
		    color:white;
	    }
        .btn{
            margin-top: 20px;
            width: 100%;
            margin-top: auto;
        }
    }

    .map {
        position: absolute;
        height: 100%;
        width: 100%;
        background: #DDD;
        min-height: 374px;
	    border:none;
	    box-shadow: none;
    }

    @media screen and (max-width: 991.98px){
        .box{
            flex-direction: column;
        }
        .map {
            flex:1;
            position: relative;
            height: 100%;
            width: 100%;
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .box{
            flex: 1;
        }
        .booking-info-overlay{
             flex: 1;
        }
        .map {
            position: relative;
        }
    }
</style>
