var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-country"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3"},[_c('div',{staticClass:"h3 mb-0"},[_vm._v(_vm._s(_vm.$t("titles.country")))]),_c('div',{staticClass:"btn-toolbar mb-2 mb-md-0"},[(_vm.mayEdit)?_c('small-button',{attrs:{"text":_vm.$t('terms.edit')},nativeOn:{"click":function($event){return _vm.goTo({name: 'country-edit',params:{id:_vm.routeCountry.id}})}}}):_vm._e()],1)]),(_vm.isLoading)?_c('spinner'):[_c('map-with-details',{attrs:{"pathDataArray":_vm.pathDataArray,"bounds":_vm.bounds,"gestureHandling":"none"}},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("labels.name")))]),_c('br'),_vm._v(_vm._s(_vm.routeCountry.name))]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("labels.countryCode")))]),_c('br'),_vm._v(_vm._s(_vm.routeCountry.countryCode))]),(_vm.hasGlobalSuperAdmin)?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("labels.bankInfo")))]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.routeCountry.bankInfo !== null ? _vm.routeCountry.bankInfo.replace('\n','<br>') : '')}})]):_vm._e()]),_c('action-list',{attrs:{"entity":"District","title":_vm.$tc('listHeaders.district',2),"columns":[
				{name:'id',header:this.$t('listHeaders.id')},
				{name:'name',header:this.$t('listHeaders.name')},
				{name:'pilots',header:this.$tc('listHeaders.driver',2)},
				{name:'company',header:this.$tc('listHeaders.company',2)} ],"staticParameters":{
				'country_equals': _vm.routeCountry.id,
				'explode':'company:name'
			},"item-link":{
				route: 'district-view',
				parameters: {
					id: 'id'
				}
			}},scopedSlots:_vm._u([{key:"pilots",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.pilots.length)+" ")]}},{key:"company",fn:function(slotProps){return [(typeof slotProps.data.company !== 'undefined' && slotProps.data.company !== null)?[_vm._v(_vm._s(slotProps.data.company.name))]:[_vm._v(_vm._s(_vm.$t('sentences.noChosen')))]]}}])}),_c('entity-booking-rules-form',{attrs:{"default-values":_vm.routeCountry.defaultRuleset,"disabled":true},model:{value:(_vm.ruleset.booking),callback:function ($$v) {_vm.$set(_vm.ruleset, "booking", $$v)},expression:"ruleset.booking"}}),_c('entity-currency-rules-form',{attrs:{"default-values":_vm.routeCountry.defaultRuleset,"disabled":true},model:{value:(_vm.ruleset.currency),callback:function ($$v) {_vm.$set(_vm.ruleset, "currency", $$v)},expression:"ruleset.currency"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }