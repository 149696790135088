<template/>

<script>
    export default {
        name: "MapMarker",
        mounted() {
            this.marker = new this.google.maps.Marker({
                position: this.position,
                map: this.map,
                icon: require('@/assets/images/marker.svg'),
                draggable:false,
                title: ''
            });

			this.marker.setMap(this.map);
			
        },
        props: {
			google: {
				type: Object,
				required: true
			},
			map: {
				type: Object,
				required: true
            },
            position:{
                type: Object,
                default: () => {return {lat: 0, lng: 0}}
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>