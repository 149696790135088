<template>
	<div class="single-country">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$t("titles.country")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
			<small-button v-if="mayEdit" :text="$t('terms.edit')" @click.native="goTo({name: 'country-edit',params:{id:routeCountry.id}})" />
			</div>
		</div>
		<spinner v-if="isLoading"/>
		<template v-else>
			<map-with-details :pathDataArray="pathDataArray" :bounds='bounds' gestureHandling='none'>
				<p><strong>{{$t("labels.name")}}</strong><br>{{routeCountry.name}}</p>
				<p><strong>{{$t("labels.countryCode")}}</strong><br>{{routeCountry.countryCode}}</p>
				<p v-if="hasGlobalSuperAdmin"><strong>{{$t("labels.bankInfo")}}</strong><br><span v-html="routeCountry.bankInfo !== null ? routeCountry.bankInfo.replace('\n','<br>') : ''" /></p>
			</map-with-details>
			<action-list
				entity="District"
				:title="$tc('listHeaders.district',2)"
				:columns="[
					{name:'id',header:this.$t('listHeaders.id')},
					{name:'name',header:this.$t('listHeaders.name')},
					{name:'pilots',header:this.$tc('listHeaders.driver',2)},
					{name:'company',header:this.$tc('listHeaders.company',2)},
				]"
				:staticParameters="{
					'country_equals': routeCountry.id,
					'explode':'company:name'
				}"
				:item-link="{
					route: 'district-view',
					parameters: {
						id: 'id'
					}
				}"
			>
				<template v-slot:pilots="slotProps">
					{{slotProps.data.pilots.length}}
				</template>
				<template v-slot:company="slotProps">
					<template v-if="typeof slotProps.data.company !== 'undefined' && slotProps.data.company !== null">{{slotProps.data.company.name}}</template>
					<template v-else>{{$t('sentences.noChosen')}}</template>
				</template>
			</action-list>
			<entity-booking-rules-form v-model="ruleset.booking" :default-values="routeCountry.defaultRuleset" :disabled='true' />
			<entity-currency-rules-form v-model="ruleset.currency" :default-values="routeCountry.defaultRuleset" :disabled='true' />
		</template>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import isGranted from "@/mixin/isGranted";
	import {mapGetters, mapState} from "vuex";
	import EntityCurrencyRulesForm from "@/components/forms/EntityCurrencyRulesForm";
	import EntityBookingRulesForm from "@/components/forms/EntityBookingRulesForm";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import SmallButton from "@/components/buttons/SmallButton";
	import MapWithDetails from '@/components/modules/MapWithDetails.vue';
	import {hashCode, normalizeRuleValue} from '@/helperFunctions.js';
	import rules from "@/rules";
	import ActionList from "@/components/list/ActionList";
	import permissions from "@/permissions";

	export default {
		name: "SingleCountry",
		components: {
			ActionList,
			Spinner, FieldInput, FormContainer, EntityCurrencyRulesForm, EntityBookingRulesForm, SmallButton, MapWithDetails},
		mixins: [isGranted],
		data(){
			return {
				isLoading: true,
				name: '',
				code: '',
				ruleset: {
					currency: {},
					booking: {}
				},
				rules: rules
			}
		},
		computed: {
			...mapState('concrete', {
				countries: 'countries'
			}),
			...mapGetters('concrete',{
				possibleRules: 'possibleRules'
			}),
			routeCountry(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.countries[this.$route.params.id] !== 'undefined') ? this.countries[this.$route.params.id] : false ;
			},
			pathDataArray() {
				let pathsArray = [];
				this.routeCountry.districts.forEach((district) => {
					let districtPathObject = {
						coords: JSON.parse(district.bounds),
						options: {
							strokeColor: hashCode(this.routeCountry.name+district.id*500),
							strokeOpacity: 0.8,
							strokeWeight: 2,
							fillColor: hashCode(this.routeCountry.name+district.id*500),
							fillOpacity: 0.35,
						},
						route: { name: 'district-view', params: {id: district.id} }
					}

					pathsArray.push(districtPathObject)
				});

				return pathsArray;
			},
			bounds(){
				const pointsInPaths = [];
				this.pathDataArray.forEach(function(pathGroup){
					pathGroup.coords.forEach(function(coords){
						pointsInPaths.push({lat: coords.lat, lng: coords.lng});
					});
				});
				return pointsInPaths;
			},
			mayEdit(){
				return this.viewingEntity !== false ? this.isGranted(permissions.COUNTRY.UPDATE,this.viewingEntity) : this.isGranted(permissions.COUNTRY.UPDATE) ;
			},
		},
		methods: {
			goTo(route){
				if(typeof route.params.lang === 'undefined')
					route.params.lang = this.$i18n.locale
				this.$router.push(route);
			},
			isDistrictBoundsKnown(){
				if(this.routeCountry === false || typeof this.routeCountry.districts === 'undefined' || this.routeCountry.districts.length === 0)
					return true;

				return typeof this.routeCountry.districts[0] !== 'object' || typeof this.routeCountry.districts[0].bounds === 'undefined'
			},
			fetchCountry(){
				this.isLoading = true;
				this.$store.dispatch('concrete/genericEntityRequest',{parameters: {explode:'district:bounds'},entity: {'id': this.$route.params.id,'@type':'Country'}})
				.catch(error => {
					console.log(error);
				})
				.then(() => {
					this.isLoading = false;
					if(typeof this.routeCountry.ruleset !== 'undefined')
					{
						this.possibleRules.forEach((rule) => {
							if(typeof this.routeCountry.ruleset[rule] !== 'undefined' && normalizeRuleValue(rule,this.routeCountry.ruleset[rule]) !== normalizeRuleValue(rule,this.routeCountry.defaultRuleset[rule]))
							{
								this.ruleset[rules[rule].group][rule] = this.routeCountry.ruleset[rule];
							}
						})
					}
				},() => {
					this.isLoading = false;
				})
			}
		},
		mounted()
		{
			this.fetchCountry();
		},
		watch: {
			$route: {
				deep:true,
				handler: function(){
					this.fetchCountry();
				}
			}
		}
	};
</script>

<style scoped>

</style>
