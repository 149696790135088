import { render, staticRenderFns } from "./SingleCountry.vue?vue&type=template&id=4e6f7538&scoped=true&"
import script from "./SingleCountry.vue?vue&type=script&lang=js&"
export * from "./SingleCountry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e6f7538",
  null
  
)

export default component.exports